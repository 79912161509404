@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inria Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Franchise";
  src: url("/public/assets/Franchise Font/Franchise.ttf") format("truetype"),
    url("/public/assets/Franchise Font/FranchiseFilled.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-move {
  animation: move 10s linear infinite;
}

.nav-text {
  color: #fff;
  -webkit-text-stroke-width: 0.5989841818809509px;
  -webkit-text-stroke-color: #fff;
  font-family: "Inria Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.347px;
}

.btn-layered {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.btn-layered::before,
.btn-layered::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: scale(1.3);
}

.btn-layered::before {
  background-image: url("/public/assets/btn-bottom.svg");
  left: -2px;
  z-index: 1;
}

.btn-layered::after {
  background-image: url("/public/assets/btn-top.svg");
  left: 2px;
  top: 4px;
  z-index: 0;
}

/* .btn-layered:hover::before,
.btn-layered:hover::after {
  left: 0;
  top: 0;
} */

.btn-layered span {
  position: relative;
  z-index: 2;
}
